<template>
        <div class="row">
            <div class="col-12">
                <h1>
                Payment: Success!
                </h1>
            </div>
        </div>
</template>



<script>

    export default {
        data () {
            return {
            };
        },

        mixins: [
        ],

        computed: {

        },

        methods: {

        },

        async created() {

        },

        async mounted() {

        }
    }
</script>
